@font-face {
    font-family: 'baloo';
    src: url('../fonts/baloobhaiextrabold/BalooBhai2-ExtraBold.woff2') format('woff2'),
        url('../fonts/baloobhaiextrabold/BalooBhai2-ExtraBold.woff') format('woff'),
        url('../fonts/baloobhaiextrabold/BalooBhai2-ExtraBold.svg#BalooBhai2-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'poppinsbold';
    src: url('../fonts/poppins-bold/Poppins-Bold.eot');
    src: url('../fonts/poppins-bold/Poppins-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/poppins-bold/Poppins-Bold.woff2') format('woff2'),
        url('../fonts/poppins-bold/Poppins-Bold.woff') format('woff'),
        url('../fonts/poppins-bold/Poppins-Bold.ttf') format('truetype'),
        url('../fonts/poppins-bold/Poppins-Bold.svg#Poppins-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'poppinsextrabold';
    src: url('../fonts/poppins-extrabold/Poppins-ExtraBold.eot');
    src: url('../fonts/poppins-extrabold/Poppins-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/poppins-extrabold/Poppins-ExtraBold.woff2') format('woff2'),
        url('../fonts/poppins-extrabold/Poppins-ExtraBold.woff') format('woff'),
        url('../fonts/poppins-extrabold/Poppins-ExtraBold.ttf') format('truetype'),
        url('../fonts/poppins-extrabold/Poppins-ExtraBold.svg#Poppins-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'poppinsregular';
    src: url('../fonts/poppins-regular/Poppins-Regular.eot');
    src: url('../fonts/poppins-regular/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/poppins-regular/Poppins-Regular.woff2') format('woff2'),
        url('../fonts/poppins-regular/Poppins-Regular.woff') format('woff'),
        url('../fonts/poppins-regular/Poppins-Regular.ttf') format('truetype'),
        url('../fonts/poppins-regular/Poppins-Regular.svg#Poppins-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'poppinssemibold';
    src: url('../fonts/poppins-semibold/Poppins-SemiBold.eot');
    src: url('../fonts/poppins-semibold/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/poppins-semibold/Poppins-SemiBold.woff2') format('woff2'),
        url('../fonts/poppins-semibold/Poppins-SemiBold.woff') format('woff'),
        url('../fonts/poppins-semibold/Poppins-SemiBold.ttf') format('truetype'),
        url('../fonts/poppins-semibold/Poppins-SemiBold.svg#Poppins-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'poppinslight';
    src: url('../fonts/poppinslight/Poppins-Light.woff2') format('woff2'),
        url('../fonts/poppinslight/Poppins-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'poppinsmedium';
    src: url('../fonts/poppinsmedium/Poppins-Medium.woff2') format('woff2'),
        url('../fonts/poppinsmedium/Poppins-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'poppinsitalic';
    src: url('../fonts/poppinsitalic/Poppins-Italic.woff2') format('woff2'),
        url('../fonts/poppinsitalic/Poppins-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

