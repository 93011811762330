// bg-position(x,y) width height
$icons: (
	'aviao': -67px 0px 17px 16px,
	'casa2': 0px -42px 16px 16px,
	'down': -54px -42px 13px 13px,
	'email': -94px -46px 13px 9px,
	'email2': -26px -42px 18px 13px,
	'fone2': -42px 0px 15px 19px,
	'html': 0px 0px 32px 32px,
	'lupa': 0px -68px 13px 13px,
	'nexrt': -23px -68px 13px 13px,
	'next': -46px -68px 13px 13px,
	'prev': -69px -68px 13px 13px,
	'tel': -94px 0px 13px 13px,
	'top': -94px -23px 13px 13px,
);

@if $nativeCssIconVarialables {
	:root{
		@each $icon,$value in $icons {
			/*icon-#{$icon}*/
			--icon-#{$icon}-width: #{nth($value, 3)};
			--icon-#{$icon}-height: #{nth($value, 4)};
			--icon-#{$icon}-bg: #{nth($value, 1)} #{nth($value, 2)};
		}
	}
}

.icon{
	background-image: url('../images/sprites.png');
	background-repeat:no-repeat;
	background-size: 107px 81px;

	display: inline-block;
	vertical-align: middle;

}

.icon__mask{
	mask-image: url('../images/sprites.png');
	mask-repeat:no-repeat;
	mask-size: 107px 81px;

	display: inline-block;
	vertical-align: middle;
	background-color: currentColor;
}

@each $icon,$value in $icons {
	.#{$icon} {
		width: nth($value,3);
		height: nth($value,4);
		$sprite-position: #{nth($value,1)} #{nth($value,2)};

		&.icon{
			background-position: $sprite-position;
		}

		&.icon__mask{
			mask-position: $sprite-position;
		}
	}
}
