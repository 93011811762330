.main__menu{
	list-style: none;
	padding-left: 0px;
	margin-bottom: 0px;
	font-family: poppinssemibold;
	font-size: 15px;
	line-height: 18px;
}
.tel__topo,
.email__topo{
	display: flex;
	align-items: center;
	column-gap: 3px;
}
.bg__menu{
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0px;
	top: 0px;
	background-color: rgba(#000000, 0.6);
	animation: fadeIn 0.5s linear;
	z-index: 1050;

	&.hide{
		animation: fadeOut 0.5s linear;
	}
}
.topo{
	.topo__meio{
		background-color: #000;
		color: #fff;
	}
	&.topo--home{
		.topo__meio{
			background-color: transparent;

			@include media-breakpoint-up (lg) {
				border-bottom: 1px solid #fff;
			}
		}
	}
}
.main__menu{
	.menu__item{
		&.menu__item--active{
			.menu__link{
				color: #5063a8;
			}
		}
	}
}
.topo__pesquisa{
    position: fixed;
    top: -150px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1080;
    max-width: 900px;
    width: 90%;
    transition: all 0.5s linear;

    &.shown{
        top: 48vh;
    }
}
.segura__sociais__topo{
	display: flex;
	align-items: center;
	column-gap: 3px;
	row-gap:3px;

	.sociais__nav{
		display: flex;
		align-items: center;
		column-gap: 3px;
		row-gap: 3px;
		flex-wrap: wrap;

		a{
			width: 30px;
			height: 30px;
			border: 1px solid #fff;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #fff;
			font-size: 12px;
			line-height: 12px;
			transition: all 0.3s linear;

			&:hover{
				background-color: #000;
				color: #fff;
				border-color: #000;
				text-decoration: none;

				@include media-breakpoint-down (md) {
					background-color: #5063a8;
					color: #fff;
					border-color: #5063a8;
				}
			}
		}
	}
}
.segura__pesquisa{
	.pesquisa__form{
		display:flex;
		border-radius: 23px;
		padding: 3px;
		background-color: #fff;
		overflow: hidden;

		label{
			width: 100%;
			display: block;
			margin-bottom: 0px;
		}
	}
	.pesquisa__input{
		width: 100%;
		display: block;
		padding: 6px 20px;
		border: none;
		min-height: 40px;
	}
	.pesquisa__btn{
		width: 40px;
		height: 40px;
		background-color: #000;
		color: #fff;
		border-radius: 50%;
		border: none;
		min-width: 40px;
	}
}
@include media-breakpoint-up (lg) {
	.mbl__toggler{
		width: 40px;
		height: 40px;
		min-width: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid #fff;
		color: #fff;
		border-radius: 50%;
		align-self: center;
		background-color: transparent;
		transition: all 0.3s linear;

		&:hover{
			background-color: #fff;
			color: #000;
			text-decoration: none;
		}
	}
	.topo__cima{
		background-color: #5063a8;
		color: #fff;
		font-family: poppinslight;
		font-size: 13px;
		line-height: 16px;
		padding: 4px 0px;

		.container{
			display: flex;
			justify-content: space-between;
			align-items: center;

			@include media-breakpoint-only (lg) {
				flex-direction: column;
				padding-top: 5px;
				padding-bottom: 3px;
			}
		}
	}
	.segura__contato{
		display: flex;
		align-items: center;
	}
	.topo__mobile{
		display: none;
	}
	.topo__meio{
		.container{
			display: flex;
			min-height: 110px;
			justify-content: space-between;
			column-gap: 20px;
		}
	}
	.main__logo{
		padding: 20px 0px;
		display: flex;
		align-items: center;

		@include media-breakpoint-only (lg) {
			max-width: 180px;
			width: 100%;
		}
	}
	.meio__left{
		display: flex;
		column-gap: 20px;
	}
	.main__menu{
		display: flex;
		column-gap: 15px;

		.menu__item{
			display: flex;

			.menu__link{
				display: flex;
				align-items: center;

				&:hover{
					text-decoration: none;
				}
			}
		}
	}
}
@include media-breakpoint-down (md) {
	.mensagem__mobile{
		background-color: #5063a8;
		color: #fff;
		text-align: center;
		padding: 8px 15px;
		font-size: 12px;
		line-height: 16px;
	}
	.topo__main{
		position: fixed;
		width: 250px;
		overflow-y: auto;
		height: 100%;
		z-index: 1080;
		background-color: #000;
		transition: all 0.5s linear;
		left: -250px;
		color: #fff;
		display: flex;
		flex-direction: column;

		.segura__contato{
			display: flex;
			flex-direction: column;
			row-gap: 20px;
			padding: 20px 15px;
			font-size: 13px;
			line-height: 16px;

			.barrinha-alt,
				.barrinha{
					display: none;
				}

			& > *{
				display: flex;
				flex-direction: column;
				row-gap: 5px;


			}
		}

		.container{
			padding: 0px;
		}

		.main__logo{
			padding: 10px;
			text-align: center;
		}

		.menu__link{
			display: block;
			padding: 10px;
		}

		.topo__meio{
			order: 1;
		}
		.topo__cima{
			order: 2;
		}

		.mbl__toggler,
		.mensagem__topo{
			display: none;
		}

		&.shown{
			left: 0px;
		}
	}
	.topo__mobile{
		background-color: #000;
		color: #fff;


		.mbl__logo{
			max-width: 150px;
		}

		.container{
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 10px 15px;
		}

		.mbl__toggler{
			border: none;
			font-size: 20px;
			line-height: 20px;
			background-color: transparent;
			color: #fff;
		}
	}
}
