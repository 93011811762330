@import "_home.scss";

.interna{
	padding: 90px 0px;

	@include media-breakpoint-down (md) {
		padding: 45px 0px;
	}
}

.btn__center{
	padding-top: 40px;
	display: flex;
	justify-content: center;
}

.cases__grid{
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	column-gap: 30px;
	row-gap: 30px;

	@include media-breakpoint-down (md) {
		grid-template-columns: repeat(2, 1fr);
		column-gap: 20px;
		row-gap: 20px;
	}
	@include media-breakpoint-down (xs) {
		grid-template-columns: 1fr;
	}
}

.interna__cases{
	.clearfix{
		h1{
			font-size: 25px;
			line-height: 28px;
			color: #000;
			font-family: poppinsbold;
			letter-spacing: -0.5px;
		}
	}
}

.titulo__interna{
	text-align: center;
}

.interna__cases{
	.titulo__interna{
		max-width: 590px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 40px;
	}
}
.paginacao{
	display: flex;
	justify-content: center;
    flex-wrap: wrap;
    border-radius: none;
    border: none;
    list-style: none;
    padding-left: 0px;
	padding-top: 60px;

	.paginacao__item{
		a{
            font-family: 'poppinsbold';
            font-size: 15px;
            line-height: 15px;
            color: #fff;
            display: flex;
            width: 35px;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            height: 35px;
			min-width: 35px;
            position: relative;
            transition: all 0.3s linear;
			border: none;
			background-color: #5063a8;
			border: none;
            margin: 0px 2px;
            padding: 0px;
			@include media-breakpoint-down (sm) {
				width: 30px;
				height: 30px;
				min-width: 30px;
				font-size: 15px;
				line-height: 15px;
			}
            &:focus{
                box-shadow: none;
            }
        }

        &.active{
           a{
				color: #fff;
				background-color: #ea4046;
                text-decoration: none;
				pointer-events: none;
				border-color: #ea4046;

				&:hover{
					background-color: #ea4046;
					border-color: #ea4046;
				}
           }
        }

		&:hover{
			a{
                text-decoration: none;
				background-color: #ea4046;
				color: #fff;
				border-color: #ea4046;
            }
		}
	}
}
.contato__box{
	display: flex;
	justify-content: space-between;
	max-width: 940px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	column-gap: 10px;

	@include media-breakpoint-down (md) {
		flex-direction: column;
		row-gap: 15px;
	}

	.contato__item{
		display: flex;
		column-gap: 15px;
		font-family: poppinsmedium;
		max-width: 320px;

		@include media-breakpoint-down (md) {
			flex-direction: column;
			row-gap: 10px;
			align-items: center;
			text-align: center;
			margin-left: auto;
			margin-right: auto;
			max-width: unset;
		}

		.contato__item__icone{
			width: 40px;
			min-width: 40px;
			height: 40px;
			background-color: #5063a8;
			color: #fff;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}
.modal__custom{
	.modal-dialog{
		max-width: 600px;
		width: 100%;

		.modal-content,
		.modal-body,
		.modal-header{
			border: none;
			background-color: transparent;
			padding: 0px;
		}
		.modal-header{
			padding: 10px 0px;
			display: flex;
			justify-content: flex-end;

			button{
				padding: 0px;
				background-color: transparent;
				color: #fff;
				border: none;
				font-family: poppinsregular;
				font-size: 13px;
				line-height: 15px;
			}
		}
		.modal-body{
			background-color: #5063a8;
			padding: 80px 15px 70px;
			color: #fff;
			text-align: center;
			border-radius: 25px;
			box-shadow: 0px 10px 15px rgba(#000, 0.2);

			.modal__finalizar__titulo{
				font-size: 35px;
				line-height: 39px;
				color: #fff;
				margin-bottom: 20px;
				font-family: poppinsbold;
				max-width: 340px;
				width: 100%;
				margin-left: auto;
				margin-right: auto;
				text-align: center;
			}
			.modal__finalizar__btns{
				display: flex;
				flex-direction: column;
				row-gap: 10px;
				max-width: 460px;
				width: 100%;
				margin-left: auto;
				margin-right: auto;

				& > *{
					background-color: #fff;
					color: #5063a8;

					&:hover{
						background-color: #5063a8;
						color: #fff;
					}
				}
			}
		}

	}
}
.modal__icone{
	margin-bottom: 25px;
	text-align: center;
	font-size: 50px;
	line-height: 50px;
}
.modal__titulo{
	font-family: poppinslight;
	font-size: 30px;
	line-height: 34px;
	color: #fff;
	text-align: center;
	max-width: 350px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 15px;

	span{
		font-family: poppinsbold;
	}
}
.serv__img{
	img{
		min-height: 433px;

		@include media-breakpoint-down (md) {
			min-height: unset;
		}
	}
}
.interna__obrigado{
	padding: 120px 0px;
	background-color: #5063a8;
	color: #fff;

	.obrigado__center{
		display: flex;
		justify-content: center;
		padding-top: 15px;
	}
}

.interna__contato{
	.titulo__interna{
		max-width: 560px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 40px;
	}
}

.contato__formulario{
	border: 1px solid #dedede;
	border-radius: 34px;
	background-color: #f7f7f7;
	padding: 50px 60px;
	max-width: 920px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 40px;

	@include media-breakpoint-down (sm) {
		padding: 25px 15px;
	}
}
.v-options{
	font-size: 13px;
	line-height: 16px;
	margin: none;
	max-height: calc(36px * 4);
	border-radius: 0px;
	z-index: 500;
	box-shadow: 0px 0px 5px rgba(#000, 0.3);
	background-color: #fff;

	li{
		color: #000;
		font-family: 'poppinsregular';
		padding: 10px;

		&.selected{
			background-color: #5063a8;
			color: #fff;
		}
	}
}
.contato__input,
.v-select-output{
	width: 100%;
	display: block;
	border: none;
	background-color: #fff;
	min-height: 50px;
	border-radius: 25px;
	font-size: 13px;
	line-height: 15px;
	color: #000;
	padding: 10px 28px;
	box-shadow: 0px 0px 10px rgba(#000, 0.1);
	resize: none;

	&:focus{
		outline: none;
		box-shadow: unset;
	}
}

.custom__check__input{
	position: fixed;
	left: -100vw;

	&:checked{
		& ~ .custom__check__label{
			&:after{
				opacity: 1;
			}
		}
	}
}
.custom__check__label{
	position: relative;
	padding-left: 50px;
	cursor: pointer;
	color: #000;

	&:before{
		content: '';
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 25px;
		width: 18px;
		height: 18px;
		border-radius: 50%;
		border: 2px solid currentColor;
		transition: all 0.3s linear;
	}

	&:after{
		content: '';
		position: absolute;
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background-color: currentColor;
		top: 50%;
		left: 30px;
		transform: translateY(-50%);
		transition: all 0.3s linear;
		opacity: 0;
	}
}
.grid__contato__btn{
	display: grid;
	grid-template-columns: 1fr 130px;
	column-gap: 15px;

	@include media-breakpoint-down (md) {
		flex-direction: column;
		display: flex;
		align-items: center;
		text-align: center;
		row-gap: 10px;
	}
}
.enviar__btn{
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background-color: #5063a8;
	color: #fff;
	column-gap: 5px;
	border-radius: 25px;
	height: 50px;
	border: none;
	min-width: 130px;
	font-family: poppinssemibold;
	font-size: 14px;
	line-height: 18px;
	transition: all 0.3s linear;

	&:hover{
		background-color: #000;
		color: #fff;
	}
}
.interna__serv__det{
	.row{
		row-gap: 25px;
	}
	.col-lg-6{
		&:nth-child(2) {
			text-align: center;

			img{
				border-radius: 25px;
			}
		}
	}
}
.interna__serv__det{
	.titulo__interna{
		text-align: left;
		margin-bottom: 15px;
	}
}
.segura__voltar__btn{
	padding-top: 20px;
}
.interna__serv{
	.titulo__interna{
		max-width: 540px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 30px;
	}
}
.servicos__destaque{
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	column-gap: 30px;
	row-gap: 30px;
	margin-bottom: 30px;

	@include media-breakpoint-down (sm) {
		grid-template-columns:1fr;
	}
}
.servicos__grid{
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	column-gap: 30px;
	row-gap: 30px;

	@include media-breakpoint-down (sm) {
		grid-template-columns:1fr;
	}

	.servico__card{
		.servico__card__desc{
			font-size: 16px;
			line-height: 19px;
			font-family: poppinslight;
			letter-spacing: -0.5px;
			min-height: calc(19px * 5);
			--linhas: 5;
		}
	}
}

.interna__quem__somos{
	padding: 90px 0px 35px 0px;

	@include media-breakpoint-down (md) {
		padding: 50px 0px 25px 0px;
	}
}
.banner__quem__cima{
	text-align: center;
	margin-bottom: 70px;
}
.segura__texto__quem{
	.titulo__interna{
		text-align: center;
		max-width: 480px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 20px;
	}
}
.banner__quem__especial{
	padding: 35px 0px;
}
.linha__tempo{
	padding: 50px 0px;
	.titulo__interna{
		text-align: center;
		margin-bottom: 40px;
	}
}
.linha__listagem{position: relative;
	&:after{
		content: '';
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		height: 100%;
		background-color: #5063a8;
		width: 2px;
		z-index: -1;

		@include media-breakpoint-down (md) {
			display: none;
		}
	}
}
.linha__item{
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	column-gap:32px;
	font-size: 16px;
	line-height: 20px;
	font-family: poppinslight;

	@include media-breakpoint-down (md) {
		display: flex;
		margin-bottom: 30px;
	}

	@include media-breakpoint-up (lg) {
		&:nth-child(even) {
			.linha__item__conteudo{
				grid-column: 2 / 3;
			}
		}
	}

	.linha__item__titulo{
		font-family: poppinsbold;
		font-size: 20px;
		line-height: 24px;
		margin-bottom: 15px;

		@include media-breakpoint-down (sm) {
			font-size: 16px;
			line-height: 20px;
		}
	}
	.linha__item__desc{
		text-align: justify;
	}

	.linha__item__numero{
		position: absolute;
		left:50%;
		top: 50%;
		background-color: #000;
		color: #fff;
		transform: translate(-50%, -50%);
		width: 60px;
		height: 60px;
		border-radius: 50%;
		font-family: poppinsbold;
		font-size: 20px;
		line-height: 24px;
		display: flex;
		align-items: center;
		justify-content: center;

		@include media-breakpoint-down (md) {
			width: 50px;
			height: 50px;
			position: absolute;
			left: -15px;
			top: 50%;
			transform: translateY(-50%);
		}
	}
}
.interna__politica{
	.titulo__interna{
		margin-bottom: 35px;
	}
}
.linha__item__conteudo{
	background-color: #5063a8;
	color: #fff;
	padding: 40px;
	border-radius: 25px;

	@include media-breakpoint-down (sm) {
		font-size: 14px;
		line-height: 17px;
	}

	@include media-breakpoint-down (md) {
		padding: 15px 15px 15px 45px;
		border-radius: 15px;

	}
}
.segura__compilance{
	background-color: #5063a8;
	color: #fff;
	padding: 35px 30px;
	margin-top: 40px;
	border-radius: 30px;


	@include media-breakpoint-down (sm) {
		padding: 20px 15px;
	}

	.comp__titulo{
		font-family: baloo;
		font-size: 40px;
		line-height: 44px;
		margin-bottom: 10px;

		@include media-breakpoint-down (sm) {
			font-size: 24px;
			line-height: 28px;
		}
		.comp__desc{
			text-align: justify;
		}
	}
}
