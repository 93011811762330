.rodape{
	background-color: #5063a8;
	color: #fff;
	font-family: poppinslight;

	.rodape__cima{
		padding: 56px 0px 60px;
		border-bottom: 1px solid #fff;

		@include media-breakpoint-down (md){
			padding: 40px 0px;
		}
	}
	.rodape__meio{
		padding: 70px 0px 60px;
		border-bottom: 1px solid #fff;

		@include media-breakpoint-down (md){
			padding: 40px 0px;
		}
	}
}
.rodape__creditos{
	padding: 20px 0px 22px 0px;
	font-size: 12px;
	line-height: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	column-gap: 5px;

	@include media-breakpoint-down (md) {
		flex-direction: column;
		row-gap: 5px;
		padding: 15px 0px;
	}
}
.gv8__box{
	display: flex;
	align-items: center;
	column-gap: 5px;
}
.titulo__news{
	font-family: baloo;
	font-size: 40px;
	line-height: 44px;
	text-align: center;
	margin-bottom: 5px;
}
.news__sub{
	font-family: poppinslight;
	font-size: 16px;
	line-height: 20px;
	margin-bottom: 25px;
	text-align: center;
}
.news__form{
	display: grid;
	grid-template-columns: 1fr 1fr 50px;
	column-gap: 10px;
	max-width: 790px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;

	@include media-breakpoint-down (sm) {
		display: flex;
		flex-direction: column;
		align-items: center;
		row-gap: 20px;
	}

	.news__group{
		width: 100%;
	}
}
.news__input{
	background-color: #fff;
	border: none;
	width: 100%;
	padding: 16px 20px;
	border-radius: 25px;
}
.news__btn{
	width: 50px;
	height: 50px;
	min-width: 50px;
	background-color: #000;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
}
.logo__hold{
	display: flex;
	align-items: flex-end;
	column-gap: 20px;
	row-gap: 20px;
	margin-bottom: 20px;

	@include media-breakpoint-only (lg) {
		flex-direction: column;
		align-items: flex-start;
	}
	@include media-breakpoint-only (xs) {
		flex-direction: column;
		align-items: center;
	}
}
.sociais__rodape{
	display: flex;
	align-items: center;
	column-gap: 5px;
	row-gap: 5px;
	flex-wrap: wrap;

	a{
		width: 40px;
		height: 40px;
		min-height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid #fff;
		color: #fff;
		transition: all 0.3s linear;
		font-size: 18px;
		line-height: 18px;
		border-radius: 50%;

		&:hover{
			background-color: #000;
			color: #fff;
			border-color: #000;
			text-decoration: none;
		}
	}
}
.rodape__desc{
	text-align: justify;

	@include media-breakpoint-down (md) {
		margin-bottom: 30px;
	}
}
@include media-breakpoint-up (lg) {
	.rodape__meio{
		display: flex;
		justify-content: space-between;
		column-gap: 49px;

		.col__1{
			width: 100%;
			position: relative;
		}
		.col__2{
			max-width: 240px;
			width: 100%;
			position: relative;

			&:after{
				width: 1px;
				height: 100%;
				content: '';
				position: absolute;
				left: -25px;
				top: 0px;
				background-color: #fff;
			}
		}
		.col__3{
			max-width: 250px;
			width: 100%;
			position: relative;

			&:after{
				width: 1px;
				height: 100%;
				content: '';
				position: absolute;
				left: -25px;
				top: 0px;
				background-color: #fff;
			}
		}
	}
}
.info__rodape{
	margin-bottom: 25px;
}
.info__rodape__titulo{
	position: relative;
	display: flex;
	align-items: center;
	column-gap: 8px;
	font-family: poppinssemibold;
	margin-bottom: 5px;

	// &:before{
	// 	display: inline-block;
	// 	content: '';
	// 	background-color: #000;
	// 	border-radius: 50%;
	// 	width: 13px;
	// 	height: 13px;
	// 	min-width: 13px;
	// }
}
.info__rodape__links{
	display: flex;
	align-items: center;
	column-gap: 3px;
}
.btn__conteudo{
	min-width: 190px;
	height: 50px;
	border-radius: 25px;
	background-color: transparent;
	color: #000;
	border: 1px solid #000;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	transition: all 0.3s linear;
	font-family: poppinssemibold;

	&:hover{
		background-color: #5063a8;
		border-color: #5063a8;
		color: #fff;
		text-decoration: none;
	}

	&.alt{
		border-color: #fff;
		color: #fff;

		&:hover{
			background-color: #fff;
			border-color: #fff;
			color: #5063a8;
			text-decoration: none;
		}
	}
}
