
.vert .carousel-item-next.carousel-item-left,
.vert .carousel-item-prev.carousel-item-right {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.vert .carousel-item-next,
.vert .active.carousel-item-right {
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100% 0);
}

.vert .carousel-item-prev,
.vert .active.carousel-item-left {
-webkit-transform: translate3d(0,-100%, 0);
        transform: translate3d(0,-100%, 0);
}

.contato{
	padding: 35px 0px 70px 0px;

	@include media-breakpoint-down (sm) {
		padding: 20px 0px 50px 0px;
	}
}

.contato__desc__home{
	font-size: 16px;
	line-height: 19px;
	margin-bottom: 30px;
}

.titulo__contato,
.titulo__home,
.titulo__interna,
.somos__titulo{
	font-family: baloo;
	font-size: 40px;
	line-height: 40px;
	color: #5063a8;

	@include media-breakpoint-down (sm) {
		font-size: 24px;
		line-height: 28px;
	}
}

.projeto__card{
	display: block;
	width: 100%;
	max-width: 350px;
	margin-left: auto;
	margin-right: auto;
	box-shadow: 0px 0px 10px rgba(#000, 0.3);
	overflow: hidden;
	border-radius: 30px;

	.projeto__card__conteudo{
		padding: 30px;

		.projeto__card__titulo{
			font-family: poppinsbold;
			font-size: 14px;
			line-height: 18px;
			min-height: calc(18px * 4);
			--linhas: 4;
			letter-spacing: -0.5px;
			transition: all 0.3s linear;
		}
	}

	&:hover{
		text-decoration: none;

		.projeto__card__titulo{
			color: #5063a8;
		}
	}
}
.projetos__header{
	row-gap: 15px;
	align-items: flex-end;
	margin-bottom: 30px;
}
.projetos{
	padding: 35px 0px;
	@include media-breakpoint-down (sm) {
		padding: 20px 0px;
	}
	.owl__custom{
		.owl-item{
			padding: 10px;
		}
	}
}
.clientes{
	padding: 35px 0px;
	@include media-breakpoint-down (sm) {
		padding: 20px 0px;
	}
	.titulo__home{
		text-align: center;
		margin-bottom: 20px;
	}
}
.owl__custom.owl-carousel{
	.owl-nav{
		button.owl-prev,
		button.owl-next{
			width: 50px;
			min-width: 50px;
			height: 50px;
			display:flex;
			align-items: center;
			justify-content: center;
			border: 1px solid #000;
			border-radius: 50%;
			background-color: #fff;
			transition: all 0.3s linear;
			color: #000;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);

			&:hover{
				background-color: #5063a8;
				border-color: #5063a8;
				color: #fff;
				text-decoration: none;
			}
		}
		button.owl-prev{
			left: -15px;

			@media (min-width: 1300px){
				left: -55px;
			}
		}
		button.owl-next{
			right: -15px;

			@media (min-width: 1300px){
				right: -55px;
			}
		}
	}
}
.parceiro__card{
	width: 100%;
	display: block;
	max-width: 170px;
	margin-left: auto;
	margin-right: auto;
	border: 1px solid #e4e4e4;
	border-radius: 15px;
}

.banner__home{
	position: relative;

	&:after{
		width: 100%;
		max-width: 1100px;
		height: 5px;
		position: absolute;
		top: 100%;
		left: 50%;
		transform: translateX(-50%);
		background-color: #5063a8;
		content: '';
	}

	@include media-breakpoint-up (lg) {
		margin-top: -110px;
	}
}
.servicos{
	padding: 80px 0px 35px 0px;

	@include media-breakpoint-down (sm) {
		padding: 50px 0px 25px 0px;
	}

	.row{
		row-gap: 15px;
	}
}
.segura__servico__home{
	margin-bottom: 40px;
}
.servicos__bs{
	.carousel-controls__custom{
		display: flex;
		column-gap: 10px;
		position: absolute;
		right: 0px;
		top: -90px;

		@include media-breakpoint-down (md) {
			position: relative;
			top: unset;
			padding-top: 20px;
			justify-content: center;
		}
		& > *{
			width: 50px;
			height: 50px;
			min-width: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid #000;
			color: #000;
			background-color: transparent;
			border-radius: 50%;
			transition: all 0.3s linear;

			&:hover{
				background-color: #5063a8;
				color: #fff;
				border-color: #5063a8;
				text-decoration: none;
			}
		}
	}
}
.servicos__hold{
	& > *{
		&:nth-child(2) {
			margin-top: 25px;
		}
	}
}
.solucoes{
	padding: 35px 0px;
	@include media-breakpoint-down (sm) {
		padding: 20px 0px;
	}

	.row{
		align-items: center;
		margin-bottom: 40px;
		row-gap: 15px;

		.contato__desc__home{
			margin-bottom: 0px;
		}
	}
}
.btns__flex{
	display: flex;
	column-gap: 30px;
	row-gap: 10px;
	flex-wrap: wrap;
}
.somos{
	overflow: hidden;
	font-family: poppinslight;
	padding: 35px 0px;

	@include media-breakpoint-down (sm) {
		padding: 20px 0px;
	}

	.clearfix{
		font-size: 16px;
		line-height: 19px;
		text-align: justify;
		margin-bottom: 25px;

		strong{
			font-family: poppinssemibold;
		}
	}

	.somos__row{
		display: flex;
		align-items: center;

		@include media-breakpoint-down (md) {
			flex-direction: column;
			row-gap: 15px;

			.somos__img{
				order: 2;
				img{
					border-radius: 30px;
				}
			}
			.somos__conteudo{
				order: 1;
				background-color: #079f00;
				color: #fff;
				padding: 25px 15px;
				border-radius: 15px;

				.somos__titulo{
					color: #fff;
					margin-bottom: 15px;
				}
			}
		}

		@include media-breakpoint-up (lg) {
			.somos__img{
				min-height: 700px;
				position: relative;
				z-index: 100;
				width: 100%;

				img{
					min-width: 945px;
					right: -160px;
					max-width: unset;
					position: absolute;
					border-radius: 0px 30px 30px 0px;
				}
			}
			.somos__conteudo{
				background-color: #079f00;
				color: #fff;
				z-index: 150;
				padding: 60px 50px;
				border-radius: 30px 0px 0px 30px;
				max-width: 770px;
				position: relative;

				.somos__titulo{
					color: #fff;
					margin-bottom: 20px;
				}

				&:after{
					width: 100vw;
					left: 0px;
					border-radius: 30px 0px 0px 30px;
					top: 0px;
					position: absolute;
					content: '';
					z-index: -1;
					height: 100%;
					background-color: #079f00;
				}
			}
		}
	}
}
.solucao__grid{
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	column-gap: 30px;
	row-gap: 30px;

	@include media-breakpoint-down (md) {
		grid-template-columns: repeat(2, 1fr);
		column-gap: 15px;
		row-gap: 15px;
	}
}
.solucao__card{
	padding: 30px 40px;
	background-color: #5063a8;
	color: #fff;
	border-radius: 30px;

	@include media-breakpoint-down (xs) {
		padding: 15px;
		border-radius: 15px;
	}

	.solucao__card__titulo{
		font-family: poppinsbold;
		font-size: 20px;
		line-height: 24px;

		@include media-breakpoint-down (md) {
			font-size: 16px;
			line-height: 20px;
		}
	}

	.solucao__card__icone{
		min-width: 50px;
		width: 50px;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid #fff;
		border-radius: 50%;
		margin-bottom: 15px;

		.segura__icone{
			min-width: 30px;
			width: 30px;
			height: 30px;
			display: inline-block;
			background-color: #fff;
			mask-size: auto;
			mask-position: center;
			mask-repeat: no-repeat;
		}
	}
}
.servico__card{
	display: block;
	width: 100%;
	max-width: 540px;
	margin-left: auto;
	margin-right: auto;
	padding: 40px 40px 24px 40px;
	border-radius: 26px;
	color: #fff;
	background-color: #5063a8;
	transition: all 0.3s linear;

	@include media-breakpoint-down (md) {
		padding: 25px 15px;
	}

	&:hover{
		background-color: #000;
		color: #fff;
		text-decoration: none;
	}

	.servico__card__header{
		display: flex;
		align-items: center;
		column-gap: 10px;
		font-family: poppinsbold;
		font-size: 20px;
		line-height: 24px;
		margin-bottom: 10px;

		.servico__card__icone{
			min-width: 50px;
			width: 50px;
			height: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid #fff;
			border-radius: 50%;

			.segura__icone{
				min-width: 30px;
				width: 30px;
				height: 30px;
				display: inline-block;
				background-color: #fff;
				mask-size: auto;
				mask-position: center;
				mask-repeat: no-repeat;
			}
		}
	}
	.servico__card__titulo{
		@extend .line__clamp;

		@include media-breakpoint-down (md) {
			font-size: 16px;
			line-height: 20px;
		}
	}
	.servico__card__desc{
		font-size: 16px;
		line-height: 20px;
		min-height: calc(20px * 4);
		--linhas: 4;
		text-align: justify;
		font-family: poppinslight;
		letter-spacing: -0.5px;
	}
}
.serv__img{
	text-align: center;

	img{
		border-radius: 20px;
	}
}
